import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./style.scss";
export default function LaunchTypeItem(props) {
  const { icon, title = "title", state = "State" } = props;

  return (
    <div className="icon-box-style2 c-launchtype">
      <div className="icon">
        <div className={`${state.toLowerCase()}`}>{icon}</div>
        {title}
        <div className="c-info">
          <InfoOutlinedIcon />
        </div>
      </div>
      <div className={`${state.toLowerCase()} content c-launchtype-btn`}>
        {state.toLowerCase() === "success" ? "Selected" : "Select"}
      </div>
    </div>
  );
}
