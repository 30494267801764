import React, { useState } from "react";

import PageTitle from "../components/pagetitle";
import CTA from "../components/cta/cta_v2";
import { Link } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import data from "../assets/fake-data/data-project";
import PreSale from "../components/presales";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { FormCheck, FormSelect } from "react-bootstrap";
import CustomSwitch from "../components/switch";
import CustomSelect from "../components/select";
import CustomMultiSelect from "../components/multiselects";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function Project1(props) {
  const [dataTab] = useState([
    {
      id: 1,
      title: "Open IGO",
    },
    {
      id: 2,
      title: "Upcoming",
    },
    {
      id: 3,
      title: "Past IGO",
    },
  ]);
  return (
    <div className="inner-page">
      {<PageTitle />}

      {/* {
        <PreSale
          title="Presale"
          items={[
            { title: "Sales Created" },
            { title: "Upcoming Sales" },
            { title: "Finished Sales" },
          ]}
        />
      } */}
      <section className="tf-section project">
        <div className="fluid">
          <div className="row">
            <div className="col-md-12">
              <div
                className="flat-tabs"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Tabs>
                  <div className="wrapper_menutab px-5 c-tabnav">
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn rounded-pill c-custom-btn"
                      >
                        Create Sale
                      </button>
                      <IconButton
                        className="ms-2 text-white"
                        aria-label="add an alarm"
                      >
                        <HelpOutlineIcon />
                      </IconButton>
                    </div>
                    <CustomSwitch />
                    <CustomSwitch />
                    <div
                      className="seclect-box align-items-center"
                      data-aos="fade-in"
                      data-aos-duration="800"
                    >
                      <CustomSelect />
                      <CustomSelect />
                      <CustomMultiSelect />
                    </div>
                  </div>

                  {dataTab.map((idx) => (
                    <TabPanel key={idx.id} className="content-tab mt40 px-4">
                      <div className="content-inner project-box-style6_wrapper">
                        {data.slice(24, 28).map((item) => (
                          <div
                            key={item.id}
                            className="project-box-style6 col-sm-6 col-md-4 px-2 col-lg-3"
                          >
                            <span className="boder"></span>
                            <div className="img-box">
                              <div className="image c-projectimage">
                                <img
                                  src={require("../assets/images/CTA.jpg")}
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <div className="img-box">
                                  <div className="content-inner">
                                    <div class>
                                      <Link to="#">{item.title}</Link>
                                    </div>
                                    <p className="desc">
                                      {item.desc?.slice(0, 10)}
                                    </p>
                                  </div>
                                </div>
                                <div className="content-bottom">
                                  <div className="content-progress-box style2">
                                    <div
                                      className="progress-bar"
                                      data-percentage="95%"
                                    >
                                      <p className="progress-title-holder">
                                        <span className="progress-title">
                                          <span className="progress_number">
                                            <span className="white">
                                              Total Raised:{" "}
                                            </span>
                                            <span className="progress-number-mark">
                                              <span className="percent"></span>
                                            </span>
                                          </span>
                                          <span>$450,000 / $500,000</span>
                                        </span>
                                      </p>
                                      <div className="progress-content-outter">
                                        <div className="progress-content"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <ul className="sub-content">
                                    <li>
                                      <p>278K</p>
                                    </li>
                                    <li>
                                      <p>Public Access</p>
                                    </li>
                                    <li>
                                      <p>$0</p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
            <div className="col-md-12">
              <ul
                className="panigation mt6"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <li>
                  <Link to="#">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.5 1L1.5 6L6.5 11"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="active">
                    1
                  </Link>
                </li>
                <li>
                  <Link to="#">2</Link>
                </li>
                <li>
                  <Link to="#">3</Link>
                </li>
                <li>
                  <Link to="#">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1L6.5 6L1.5 11"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Project1;
