import React from "react";

import img from "../../assets/images/contact-logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

function CTA(props) {
  const [dataTitle] = useState({
    title:
      "Benefit from our parnetship with validators get early access to launches",
    subtitle: "Get better opportunities by delegating to ",
  });
  return (
    <section className="tf-section tf_CTA">
      <div className="overlay"></div>
      <div className="fluid relative px-5 mx-5">
        <div className="row">
          <div className="col-md-6">
            <div
              className="tf-title left mt58"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2 className="title ">{dataTitle.title}</h2>
              <p className="sub h4">{dataTitle.subtitle}</p>
              <div className="wrap-btn">
                <Link to="/submit_igo" className="tf-button style1">
                  Learn more
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div
              className="image_cta ps-5 ms-5"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="move4 c-CTA-image">
                <div>
                  <img src={img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;
