import React, { useState } from "react";

import PageTitle from "../components/pagetitle";
import CTA from "../components/cta/cta_v2";
import { Link } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import data from "../assets/fake-data/data-project";
import PreSale from "../components/presales";

function Project2(props) {
  const [dataTab] = useState([
    {
      id: 1,
      title: "Open IGO",
    },
    {
      id: 2,
      title: "Upcoming",
    },
    {
      id: 3,
      title: "Past IGO",
    },
  ]);
  return (
    <div className="inner-page">
      {<PageTitle />}

      {<PreSale title="Mint your own Token" />}
      <section className="tf-section project"></section>
    </div>
  );
}

export default Project2;
