import React from "react";
import "../scss/component/_section.scss";
import "../scss/component/_box.scss";
import "../scss/component/_tf-section.scss";
import "../scss/component/_reponsive.scss";
import Banner from "../components/banner/banner_v1";
import dataBanner from "../assets/fake-data/data-banner";
import dataProject from "../assets/fake-data/data-project";
import Project3 from "../components/project/project_v3";
import Project4 from "../components/project/project_v4";
import Trends from "../components/trends";

import Team from "../components/team";
import Partner from "../components/partner";
import dataPartner from "../assets/fake-data/data-partner";
import CTA from "../components/cta";
import dataTeam from "../assets/fake-data/data-team";
import ProjectSales from "../components/project/project_sales";
import dataFeatured from "../assets/fake-data/data-feature";
import Ideas from "../components/Ideas";
function HomeOne(props) {
  return (
    <div className="header-fixed main home1 counter-scroll">
      {<Banner data={dataBanner} />}

      {<Ideas data={dataFeatured} />}

      {<ProjectSales data={dataProject} />}

      {<Trends />}

      {/* <Project4 data={dataTeam} /> */}

      <CTA />
    </div>
  );
}

export default HomeOne;
