import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";

ProjectSales.propTypes = {
  data: PropTypes.array,
};

function ProjectSales(props) {
  const { data } = props;
  return (
    <section className="tf-section project2">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-5 mt-5">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2 className="title">Follow our latest Sales</h2>
            </div>
          </div>
          <div className="col-md-12">
            <div className="fluid">
              <Swiper
                className="slider-8"
                modules={[Autoplay]}
                autoplay={{ delay: 1000 }}
                spaceBetween={20}
                slidesPerView={4}
                // breakpoints={{
                //   0: {
                //     slidesPerView: 1,
                //   },
                //   600: {
                //     slidesPerView: 2,
                //   },

                //   991: {
                //     slidesPerView: 3,
                //   },

                //   1200: {
                //     slidesPerView: 4,
                //   },
                // }}
              >
                {data.slice(15, 23).map((item) => (
                  <SwiperSlide key={item.id}>
                    <div
                      key={item.id}
                      // className="col-lg-3 col-md-6"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <div className="image-box c-sales">
                        <div className="number">
                          <img
                            src={require("../../../assets/images/backgroup/bg_box.png")}
                            alt=""
                            className={item.price_1?.toLowerCase()}
                          />
                          <div className=""></div>
                          <h6>{item.price_1}</h6>
                        </div>
                        <div className="image">
                          <img src={item.img} alt="" />
                        </div>
                        <div className="content">
                          <h6 className="name ">
                            <Link
                              to="#"
                              className=" text-truncate text-ellipsis w-100"
                            >
                              {item.title}
                            </Link>
                          </h6>
                          <ul>
                            <li className="box bg_style">
                              <div>
                                <div
                                  className="progress-bar"
                                  data-percentage="50.3%"
                                >
                                  <p className="progress-title-holder mb-2">
                                    <span className="progress-number-wrapper">
                                      <span className="progress-number-mark">
                                        <span className="percent">
                                          8.141 ETH Raised
                                        </span>
                                      </span>
                                    </span>
                                  </p>
                                  <div className="progress-content-outter">
                                    <div className="progress-content"></div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="box bg_style">
                              {item.price_2 ? item.price_2 : "Today"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <hr className="mb-5" />
            <div className="fluid">
              <Swiper
                className="slider-8"
                modules={[Autoplay]}
                autoplay={{ delay: 1300 }}
                spaceBetween={20}
                slidesPerView={4}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  600: {
                    slidesPerView: 2,
                  },

                  991: {
                    slidesPerView: 3,
                  },

                  1200: {
                    slidesPerView: 4,
                  },
                }}
              >
                {data.slice(15, 23).map((item) => (
                  <SwiperSlide key={item.id}>
                    <div
                      key={item.id}
                      // className="col-lg-3 col-md-6"
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <div className="image-box c-sales">
                        <div className="number">
                          <img
                            src={require("../../../assets/images/backgroup/bg_box.png")}
                            alt=""
                            className={`success`}
                          />
                          <div className=""></div>
                          <h6>Success</h6>
                        </div>
                        <div className="image">
                          <img src={item.img} alt="" />
                        </div>
                        <div className="content">
                          <h6 className="name">
                            <Link
                              to="#"
                              className=" text-truncate text-ellipsis w-100"
                            >
                              {item.title}
                            </Link>
                          </h6>
                          <ul>
                            <li className="box bg_style">
                              <div>
                                <div
                                  className="progress-bar"
                                  data-percentage="50.3%"
                                >
                                  <p className="progress-title-holder mb-2">
                                    <span className="progress-number-wrapper">
                                      <span className="progress-number-mark">
                                        <span className="percent">
                                          8.141 ETH Raised
                                        </span>
                                      </span>
                                    </span>
                                  </p>
                                  <div className="progress-content-outter">
                                    <div className="progress-content"></div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="box bg_style">Sale Ended</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectSales;
