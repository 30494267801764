import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import img from "../../assets/images/backgroup/bg_team_section.png";
import shape from "../../assets/images/common/shape_3.png";
import shape1 from "../../assets/images/common/shape_4.svg";
import { useState } from "react";
import Button from "../button";

Team.propTypes = {
  data: PropTypes.array,
};

function Team(props) {
  const { data } = props;

  const [dataTitle] = useState({
    title: "",
  });
  return (
    <section className="tf-section tf_team">
      {/* <div className="overlay">
        <img src={img} alt="" />
      </div> */}
      <div className="fluid px-5 mx-5">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title mb40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2 className="title">{dataTitle.title}</h2>
            </div>
          </div>
          <div className="col-md-12 fluid mb-5">
            <div
              className="rounded  w-100 h-100 row c_show fluid"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="col-md-12 col-lg-6 row p-5">
                <div className="col-12 mb-4 text-white title">
                  <h3>
                    Venture into the world of token creation with BDX Mint
                  </h3>
                </div>
                <div className="col-12 mb-5 text-white">
                  BDX Mint is a tool that allows you to create your own token in
                  seconds.
                </div>
                <div className="c-button">
                  <Button
                    title="Create Token"
                    addClass="border border-1 rounded-pill style2"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 p-3 tf-section features">
                <div className="wrapper-box justify-content-start">
                  {data.slice(4, 9).map((item) => (
                    <div key={data[4].id} className="icon-box-style2">
                      <div className="icon">
                        <img src={data[4].img} alt="" />
                      </div>
                      <div className="content">
                        <h5 className="title">{data[4].title}</h5>
                        <p>{data[4].unit}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 fluid mb-5">
            <div
              className="rounded  w-100 h-100 row c_show fluid"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="col-md-12 col-lg-6 row p-5">
                <div className="col-12 mb-4 text-white title">
                  <h3>
                    Unleash your long-awaited project in mere minutes with our
                    simple launch product BDX Sale
                  </h3>
                </div>
                <div className="col-12 mb-5 text-white">
                  BDX Sale give you all the tools you need in an easy, stress
                  free way to carry your presale.
                </div>
                <div className="c-button">
                  <Button
                    title="Create Token"
                    addClass="border border-1 rounded-pill style2"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 p-3 tf-section features">
                <div className="wrapper-box justify-content-start">
                  {data.slice(3, 10).map((item) => (
                    <div key={data[4].id} className="icon-box-style2">
                      <div className="icon">
                        <img src={data[4].img} alt="" />
                      </div>
                      <div className="content">
                        <h5 className="title">{data[4].title}</h5>
                        <p>{data[4].unit}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
