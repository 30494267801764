import React, { useEffect, useState } from "react";

import "./styles.scss";

import { Link } from "react-router-dom";

function Footer(props) {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <footer id="footer">
      <div className="footer-main">
        <div className="fluid px-5 mx-5">
          <div className="row">
            <div className="footer-logo">
              <div className="logo_footer">
                <img
                  src={require("../../assets/images/logo/logo2.png")}
                  alt=""
                />
              </div>
              <p>&copy;2024 BDx Technologies All rights reserved</p>
            </div>
            <div className="widget">
              <h5 className="widget-title">Products</h5>
              <ul className="widget-link contact">
                <li>
                  <Link to="#">BDxMint</Link>
                </li>
                <li>
                  <Link to="#">BDxSale</Link>
                </li>
              </ul>
            </div>
            <div className="widget support">
              <h5 className="widget-title">Company</h5>
              <ul className="widget-link">
                <li>
                  <Link to="#">Our Story</Link>
                </li>
                <li>
                  <Link to="#">Press Kit</Link>
                </li>
                <li>
                  <Link to="#">Terms of Use</Link>
                </li>
                <li>
                  <Link to="#">Private Policy</Link>
                </li>
                <li>
                  <Link to="#">$Sales</Link>
                </li>
              </ul>
            </div>
            <div className="widget link">
              <h5 className="widget-title">Resources</h5>
              <ul className="widget-link">
                <li>
                  <Link to="#">Documentation</Link>
                </li>
                <li>
                  <Link to="#">Support Chat</Link>
                </li>
                <li>
                  <Link to="#">Maketing Srevice</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="wrap-fx">
            <div className="Copyright">
              Copyright © 2023. Designed by <Link to="#">Themesflat</Link>
            </div>
          </div>
        </div>
      </div>

      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </footer>
  );
}

export default Footer;
