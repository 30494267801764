import React from "react";
import { Link } from "react-router-dom";
import Button from "../button";

export default function PresaleItem(props) {
  const { content } = props;
  return (
    <section
      className="tf-section tf-wallet pb-0"
      data-aos-delay="500"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="wallet-form">
              <h4>{content.title}</h4>
              <div className="spacing"></div>

              <div className="connect-wallet">
                <ul>
                  <li>
                    <Link to="#">
                      <img
                        src={require("../../assets/images/common/wallet_5.png")}
                        alt=""
                      />
                      <span>MetaMask</span>
                      <span className="icon">
                        <svg
                          width="8"
                          height="14"
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.1875 1.375L6.8125 7L1.1875 12.625"
                            stroke="#798DA3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link to="#">
                      <img
                        src={require("../../assets/images/common/wallet_6.png")}
                        alt=""
                      />
                      <span>Coibase Walet</span>
                      <span className="icon">
                        <svg
                          width="8"
                          height="14"
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.1875 1.375L6.8125 7L1.1875 12.625"
                            stroke="#798DA3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link to="#">
                      <img
                        src={require("../../assets/images/common/wallet_7.png")}
                        alt=""
                      />
                      <span>WaletConnect</span>
                      <span className="icon">
                        <svg
                          width="8"
                          height="14"
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.1875 1.375L6.8125 7L1.1875 12.625"
                            stroke="#798DA3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="bottom d-flex ">
                <Button title="Selected" addclass="style1 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
