import img1 from "../images/slider/Furore.png";
import img2 from "../images/slider/icon_1.png";
import img3 from "../images/slider/icon_2.png";
import img4 from "../images/slider/icon_3.png";
import coin1 from "../../assets/images/common/coin1.png";
import coin2 from "../../assets/images/common/coin2.png";
import coin3 from "../../assets/images/common/coin3.png";
import coin4 from "../../assets/images/common/coin4.png";
import coin5 from "../../assets/images/common/coin5.png";
import coin6 from "../../assets/images/common/coin6.png";
const dataBanner = [
  {
    id: 1,
    heading: "Bittensor Decentralized Launchpad",
    sub_heading: "Help devs raise money for their subnets. ",
    img1: coin1,
    img2: coin2,
    img3: coin3,
    img4: coin4,
    img5: coin5,
    img6: coin6,
  },
  {
    id: 2,
    heading: "Enter the gateway of Blockchain Gaming",
    sub_heading:
      "Visually and spatially connecting games in a seamless metaverse experience",
    img1: coin1,
    img2: coin2,
    img3: coin3,
    img4: coin4,
    img5: coin5,
    img6: coin6,
  },
];

export default dataBanner;
