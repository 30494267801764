import React from "react";
import { Link } from "react-router-dom";
import PresaleItem from "./presaleitem";

export default function PreSale(props) {
  const { title, items } = props;
  return (
    <section
      className="tf-section "
      data-aos-delay="500"
      data-aos="fade-up"
      data-aos-duration="800"
      style={{ marginTop: "-100px" }}
    >
      <div className="fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="wallet-form c-presale">
              <h3 className="mb-5 pb-5">{props.title}</h3>
              <div className="spacing"></div>

              <div className="connect-wallet row">
                <div className="col-md-6 col-lg-4">
                  <PresaleItem content={items[0]} />
                </div>
                <div className="col-md-6 col-lg-4">
                  <PresaleItem content={items[1]} />
                </div>
                <div className="col-md-12 col-lg-4">
                  <PresaleItem content={items[2]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
