import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Partner3 from "../partner/Partner3";
import dataPartner from "../../assets/fake-data/data-partner";

PageTitle.propTypes = {
  title: PropTypes.string,
};

function PageTitle(props) {
  const { title } = props;
  return (
    <section className="page-title">
      <div className="overlay" style={{ zIndex: "-1" }}></div>
    </section>
  );
}

export default PageTitle;
