import React, { useState } from "react";

import PageTitle from "../components/pagetitle";
import CTA from "../components/cta/cta_v2";
import { Link } from "react-router-dom";
import LaunchTypeItem from "../components/launchtype";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ContactEmergencyOutlinedIcon from "@mui/icons-material/ContactEmergencyOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PercentIcon from "@mui/icons-material/Percent";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import GradingIcon from "@mui/icons-material/Grading";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GitHubIcon from "@mui/icons-material/GitHub";
import XIcon from "@mui/icons-material/X";
import RedditIcon from "@mui/icons-material/Reddit";
import TelegramIcon from "@mui/icons-material/Telegram";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RecentActorsIcon from "@mui/icons-material/RecentActors";

import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";

import Button from "../components/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
function CreateSale(props) {
  const [age, setAge] = React.useState("");
  const [youtube, setYoutube] = useState({ active: false, url: "" });
  const [github, setGithub] = useState({ active: false, url: "" });
  const [twitter, setTwitter] = useState({ active: false, url: "" });
  const [reddit, setReddit] = useState({ active: false, url: "" });
  const [telegram, setTelegram] = useState({ active: false, url: "" });

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className="inner-page">
      {<PageTitle title="Submit Project" />}
      <div
        className="title d-flex justify-content-center mb-5 pb-5"
        style={{ marginTop: "-100px", color: "white !important" }}
      >
        <h3>Create a Sale</h3>
      </div>
      <section
        className="tf-section project-info "
        data-aos-delay="500"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div className="fluid px-5">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="project-info-form check-radio ">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>1</h5>
                  </div>
                  <h5>Select Launch Type</h5>
                </div>
                <div className="form-inner row">
                  <div className="col-lg-6 wrapper-box mb-3">
                    <LaunchTypeItem
                      icon={<RocketLaunchOutlinedIcon fontSize="large" />}
                      state="Success"
                      title="Presale"
                    />
                  </div>
                  <div className="col-lg-6 wrapper-box mb-3">
                    <LaunchTypeItem
                      icon={<OpenInNewOutlinedIcon fontSize="large" />}
                      title="Fairlaunch"
                    />
                  </div>
                  <div className="col-lg-6 wrapper-box mb-3">
                    <LaunchTypeItem
                      icon={<AnalyticsOutlinedIcon fontSize="large" />}
                      title="Overflow"
                    />
                  </div>
                  <div className="col-lg-6 wrapper-box mb-3">
                    <LaunchTypeItem
                      icon={<ContactEmergencyOutlinedIcon fontSize="large" />}
                      title="Private Sale"
                    />
                  </div>
                </div>
              </div>

              <div className="project-info-form style  ">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>2</h5>
                  </div>
                  <h5>Token Information</h5>
                </div>
                <div className="form-inner row">
                  <div className="col-12 text-white align-items-center d-flex justify-content-center">
                    <ExitToAppOutlinedIcon className="me-2" />
                    Or Import Your Own Token
                  </div>
                  <div className="col-12">
                    <TextField
                      id="outlined-helperText"
                      label="Token Contract Address"
                      placeholder="Ex. 0x BX398B3A2B3B4B1455B123V1232154N"
                      helperText="Insert correct contract address"
                      className="w-100 c-launchtype-input"
                      sx={[
                        { color: "white !important", marginTop: "13px" },
                        { "*": { color: "white !important" } },
                        {
                          input: {
                            background: "transparent !important",
                            padding: "3px 8px !important",
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className="project-info-form style">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>3</h5>
                  </div>
                  <h5>PreSale Information</h5>
                </div>
                <div className="form-inner">
                  <div className="row">
                    <div className="col-12 text-white align-items-center d-flex justify-content-center title border-0 mb-0">
                      <ExitToAppOutlinedIcon className="me-2" />
                      Start with a Template
                    </div>
                  </div>
                  <div className="upload-img mb22 project-wrapper">
                    {/* <div className="col img_1">
                      <div className="box c-launchtype-template">
                        <label>
                          <span>
                            <DoubleArrowIcon />
                            <span>Low cap gem</span>
                          </span>
                          <span>For projects with a low hard cap</span>
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-pill"
                          >
                            Apply
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="col img_2">
                      <div className="box c-launchtype-template">
                        <label>
                          <span>
                            <VerticalAlignCenterIcon />
                            <span>Mid raise</span>
                          </span>
                          <span>For projects with a medium hard cap</span>
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-pill"
                          >
                            Apply
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="col img_3">
                      <div className="box c-launchtype-template">
                        <label>
                          <span>
                            <TrendingUpIcon />
                            <span>Long term raise</span>
                          </span>
                          <span>For projects with a high hard cap</span>
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-pill"
                          >
                            Apply
                          </button>
                        </label>
                      </div>
                    </div> */}
                    <Swiper
                      className="slider-8"
                      modules={[Autoplay]}
                      autoplay={{ delay: 1000 }}
                      spaceBetween={20}
                      slidesPerView={2}
                    >
                      <SwiperSlide>
                        <div className="content-wrapper c-launchtype-template">
                          <div
                            className="content_inner"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                          >
                            <div className="wrapper">
                              <h4 className="d-flex align-items-center">
                                <DoubleArrowIcon
                                  fontSize="large"
                                  className="me-2"
                                />
                                Low cap gem
                              </h4>
                              <p className="desc">
                                For projects with a low hard cap
                              </p>
                              <ul className="price d-flex justify-content-center p">
                                <li>
                                  <span>Apply</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="content-wrapper c-launchtype-template">
                          <div
                            className="content_inner"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                          >
                            <div className="wrapper">
                              <h4 className="d-flex align-items-center">
                                <DoubleArrowIcon
                                  fontSize="large"
                                  className="me-2"
                                />
                                Low cap gem
                              </h4>
                              <p className="desc">
                                For projects with a low hard cap
                              </p>
                              <ul className="price d-flex justify-content-center p">
                                <li>
                                  <span>Apply</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="content-wrapper c-launchtype-template">
                          <div
                            className="content_inner"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                          >
                            <div className="wrapper">
                              <h4 className="d-flex align-items-center">
                                <DoubleArrowIcon
                                  fontSize="large"
                                  className="me-2"
                                />
                                Low cap gem
                              </h4>
                              <p className="desc">
                                For projects with a low hard cap
                              </p>
                              <ul className="price d-flex justify-content-center p">
                                <li>
                                  <span>Apply</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div className="mb21 mt-5">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <MonetizationOnOutlinedIcon className="me-2" />
                      Presale rate and Listing rate:
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <TextField
                          id="outlined-helperText"
                          label="Presale Rate*"
                          className="w-100"
                          helperText="This is the rate at which the contributers can buy for 1 Native Token like Core, BNB"
                          type="number"
                          sx={[
                            { color: "white !important" },
                            { "*": { color: "white !important" } },
                            {
                              input: {
                                background: "transparent !important",
                                padding: "3px 8px !important",
                              },
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <TextField
                          id="outlined-helperText"
                          label="Listing Rate*"
                          className="w-100"
                          helperText="This is the rate at which the people after the presale can buy for 1 Native Token like Core, BNB"
                          type="number"
                          sx={[
                            { color: "white !important" },
                            { "*": { color: "white !important" } },
                            {
                              input: {
                                background: "transparent !important",
                                padding: "3px 8px !important",
                              },
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb21 mt-3">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <AssuredWorkloadOutlinedIcon className="me-2" />
                      Soft Cap and Hard Cap:
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <TextField
                          id="outlined-helperText"
                          label="Soft Cap*"
                          placeholder="e.g 50"
                          className="w-100"
                          helperText="This is the Minimum amount of Native Tokens that can be collected at this IDO to succed."
                          type="number"
                          sx={[
                            { color: "white !important" },
                            { "*": { color: "white !important" } },
                            {
                              input: {
                                background: "transparent !important",
                                padding: "3px 8px !important",
                              },
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <TextField
                          id="outlined-helperText"
                          label="Hard Cap*"
                          className="w-100"
                          placeholder="e.g 100"
                          helperText="This is the Maximum amount of Native Tokens that can be collected at this IDO."
                          type="number"
                          sx={[
                            { color: "white !important" },
                            { "*": { color: "white !important" } },
                            {
                              input: {
                                background: "transparent !important",
                                padding: "3px 8px !important",
                              },
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb21 mt-3">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <PersonOutlineIcon className="me-2" />
                      Contribution level per User:
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <TextField
                          id="outlined-helperText"
                          label="Min Contribution *"
                          placeholder="e.g 0.001"
                          className="w-100"
                          helperText="This is the Minimum amount of Native Tokens that can contributed per person."
                          type="number"
                          sx={[
                            { color: "white !important" },
                            { "*": { color: "white !important" } },
                            {
                              input: {
                                background: "transparent !important",
                                padding: "3px 8px !important",
                              },
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <TextField
                          id="outlined-helperText"
                          label="Max Contribution *"
                          className="w-100"
                          placeholder="e.g 1"
                          helperText="This is the Maximum amount of Native Tokens that can contributed per person."
                          type="number"
                          sx={[
                            { color: "white !important" },
                            { "*": { color: "white !important" } },
                            {
                              input: {
                                background: "transparent !important",
                                padding: "3px 8px !important",
                              },
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb21 mt-3">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <PercentIcon className="me-2" />
                      Liquidity and DEX:
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <FormControl
                          sx={[
                            {
                              minWidth: 120,
                              lineHeight: "46px !important",
                              // height: "46px !important",
                            },
                            { "*": { color: "white !important" } },
                            {
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                          className="w-100"
                        >
                          <InputLabel id="demo-simple-select-helper-label">
                            Liquidity Percentage
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={age}
                            label="Liquidity Percentage"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {Array(100)
                              .fill(0)
                              .map((_, index) => (
                                <MenuItem value={index + 1}>
                                  {index + 1}%
                                </MenuItem>
                              ))}
                          </Select>
                          <FormHelperText>
                            This is the percentage of collected funds which gets
                            send to the DEX on completion.
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormControl
                          sx={[
                            {
                              minWidth: 120,
                              lineHeight: "46px !important",
                              height: "46px !important",
                            },
                            { "*": { color: "white !important" } },
                            {
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                          className="w-100"
                        >
                          <InputLabel id="demo-simple-select-helper-label">
                            DEX
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={age}
                            label="DEX"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {Array(100)
                              .fill(0)
                              .map((_, index) => (
                                <MenuItem value={index + 1}>
                                  {index + 1}%
                                </MenuItem>
                              ))}
                          </Select>
                          <FormHelperText>
                            This is the DEX on which the liquidity will be added
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12 text-white align-items-center d-flex justify-content-center title border-0 mb-0">
                      <LocalOfferIcon className="me-2" />
                      Estimated Prices
                    </div>
                    <div className="col-12 col-sm-4 offset-sm-2 c-launchprice">
                      Token Price:
                      <span>$NAN</span>
                    </div>
                    <div className="col-12 col-sm-4 c-launchprice">
                      Diluted Market Cap:
                      <span>$NAN</span>
                    </div>
                    <div className="col-12 text-center">
                      Please keep in mind that crypto prices fluctuate and
                      estimated prices may chnage.
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-info-form style  ">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>4</h5>
                  </div>
                  <h5>Token Distribution</h5>
                </div>
                <div className="form-inner">
                  <div className="row">
                    <div className="title text-white col-12 border-0 mb-0 text-center">
                      Distribution
                    </div>
                    <div className="col-12 text-center c-launchdistribution">
                      Your required tokens of 0 exceed the total unlocked supply
                      of 0 tokens Please adjust presale/liquidity/cap rates.
                    </div>

                    <div className="col-12 text-center mt-2">
                      Total supply 0
                    </div>
                    <div className="col-12 text-center mt-2">
                      Total supply 0
                    </div>
                  </div>
                </div>
              </div>

              <div className="project-info-form style">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>5</h5>
                  </div>
                  <h5>PreSale Information</h5>
                </div>
                <div className="form-inner">
                  <div className="mb21 mt-5">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <MonetizationOnOutlinedIcon className="me-2" />
                      Presale Start Time
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <div class="form-group">
                          <TextField
                            id="outlined-helperText"
                            label="Presale Start time"
                            className="w-100"
                            value={Date()}
                            type="date"
                            sx={[
                              { color: "white !important" },
                              { "*": { color: "white !important" } },
                              {
                                input: {
                                  background: "transparent !important",
                                  padding: "3px 8px !important",
                                },
                                ".MuiFormHelperText-root": {
                                  color: "#798da3 !important",
                                },
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormControl
                          sx={[
                            {
                              minWidth: 120,
                              lineHeight: "46px !important",
                              // height: "46px !important",
                            },
                            { "*": { color: "white !important" } },
                            {
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                          className="w-100"
                        >
                          <InputLabel id="demo-simple-select-helper-label">
                            Liquidity Percentage
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={age}
                            label="Liquidity Percentage"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>Select your Interval</em>
                            </MenuItem>
                            <MenuItem value={7}>1 Week from now</MenuItem>
                            <MenuItem value={30}>1 Month from now</MenuItem>
                            <MenuItem value={365}>1 Year from now</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="mb21 mt-3">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <GradingIcon className="me-2" />
                      Presale End Time
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <div class="form-group">
                          <TextField
                            id="outlined-helperText"
                            label="Presale Start time"
                            className="w-100"
                            value={Date()}
                            type="date"
                            sx={[
                              { color: "white !important" },
                              { "*": { color: "white !important" } },
                              {
                                input: {
                                  background: "transparent !important",
                                  padding: "3px 8px !important",
                                },
                                ".MuiFormHelperText-root": {
                                  color: "#798da3 !important",
                                },
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormControl
                          sx={[
                            {
                              minWidth: 120,
                              lineHeight: "46px !important",
                              // height: "46px !important",
                            },
                            { "*": { color: "white !important" } },
                            {
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                          className="w-100"
                        >
                          <InputLabel id="demo-simple-select-helper-label">
                            Liquidity Percentage
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={age}
                            label="Liquidity Percentage"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>Select your Interval</em>
                            </MenuItem>
                            <MenuItem value={7}>1 Week from now</MenuItem>
                            <MenuItem value={30}>1 Month from now</MenuItem>
                            <MenuItem value={365}>1 Year from now</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="mb21 mt-3">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <LockOutlinedIcon className="me-2" />
                      Liquidity Unlock Time
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <div class="form-group">
                          <TextField
                            id="outlined-helperText"
                            label="Presale Start time"
                            className="w-100"
                            value={Date()}
                            type="date"
                            sx={[
                              { color: "white !important" },
                              { "*": { color: "white !important" } },
                              {
                                input: {
                                  background: "transparent !important",
                                  padding: "3px 8px !important",
                                },
                                ".MuiFormHelperText-root": {
                                  color: "#798da3 !important",
                                },
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormControl
                          sx={[
                            {
                              minWidth: 120,
                              lineHeight: "46px !important",
                              // height: "46px !important",
                            },
                            { "*": { color: "white !important" } },
                            {
                              ".MuiFormHelperText-root": {
                                color: "#798da3 !important",
                              },
                            },
                          ]}
                          className="w-100"
                        >
                          <InputLabel id="demo-simple-select-helper-label">
                            Liquidity Percentage
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={age}
                            label="Liquidity Percentage"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>Select your Interval</em>
                            </MenuItem>
                            <MenuItem value={7}>1 Week from now</MenuItem>
                            <MenuItem value={30}>1 Month from now</MenuItem>
                            <MenuItem value={365}>1 Year from now</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-info-form style">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>6</h5>
                  </div>
                  <h5>Project Information</h5>
                </div>
                <div className="form-inner">
                  <div className="mb21 mt-5">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <AddBoxOutlinedIcon className="me-2" />
                      Add a logo and banner for your project:
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <div className="form-inner">
                          <div className="upload-img mb22">
                            <div className="col img_1">
                              <div className="box">
                                <input
                                  type="file"
                                  name="file-1[]"
                                  id="file-1"
                                  className="inputfile inputfile-1"
                                  data-multiple-caption="{count} files selected"
                                  multiple=""
                                />
                                <label htmlFor="file-1">
                                  <img
                                    src={require("../assets/images/common/upload.png")}
                                    alt=""
                                  />
                                  <span>
                                    Drag and drop <br /> images
                                  </span>
                                  <span className="file"></span>
                                </label>
                              </div>
                              <p>Logo for your Sale</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-inner">
                          <div className="upload-img mb22">
                            <div className="col img_1">
                              <div className="box">
                                <input
                                  type="file"
                                  name="file-1[]"
                                  id="file-1"
                                  className="inputfile inputfile-1"
                                  data-multiple-caption="{count} files selected"
                                  multiple=""
                                />
                                <label htmlFor="file-1">
                                  <img
                                    src={require("../assets/images/common/upload.png")}
                                    alt=""
                                  />
                                  <span>
                                    Drag and drop <br /> images
                                  </span>
                                  <span className="file"></span>
                                </label>
                              </div>
                              <p>Banner for your Sale</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb21 mt-3">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <AddBoxOutlinedIcon className="me-2" />
                      Add links to your project
                      <IconButton
                        className={`${
                          youtube.state ? "ms-3" : "ms-3 text-white"
                        }`}
                        aria-label="add an alarm"
                        color={youtube.state ? "primary" : ""}
                        onClick={() => {
                          setYoutube({ ...youtube, state: !youtube.state });
                        }}
                      >
                        <YouTubeIcon />
                      </IconButton>
                      <IconButton
                        className={`${
                          github.state ? "ms-3" : "ms-3 text-white"
                        }`}
                        aria-label="add an alarm"
                        color={github.state ? "primary" : ""}
                        onClick={() => {
                          setGithub({ ...github, state: !github.state });
                        }}
                      >
                        <GitHubIcon />
                      </IconButton>
                      <IconButton
                        className={`${
                          twitter.state ? "ms-3" : "ms-3 text-white"
                        }`}
                        aria-label="add an alarm"
                        color={twitter.state ? "primary" : ""}
                        onClick={() => {
                          setTwitter({ ...twitter, state: !twitter.state });
                        }}
                      >
                        <XIcon />
                      </IconButton>
                      <IconButton
                        className={`${
                          reddit.state ? "ms-3" : "ms-3 text-white"
                        }`}
                        aria-label="add an alarm"
                        color={reddit.state ? "primary" : ""}
                        onClick={() => {
                          setReddit({ ...reddit, state: !reddit.state });
                        }}
                      >
                        <RedditIcon />
                      </IconButton>
                      <IconButton
                        className={`${
                          telegram.state ? "ms-3" : "ms-3 text-white"
                        }`}
                        aria-label="add an alarm"
                        color={telegram.state ? "primary" : ""}
                        onClick={() => {
                          setTelegram({ ...telegram, state: !telegram.state });
                        }}
                      >
                        <TelegramIcon />
                      </IconButton>
                    </label>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6">
                        <div class="form-group">
                          <TextField
                            id="outlined-helperText"
                            label="Website Link"
                            className="w-100"
                            placeholder="https://bdx.app"
                            sx={[
                              { color: "white !important" },
                              { "*": { color: "white !important" } },
                              {
                                input: {
                                  background: "transparent !important",
                                  padding: "3px 8px !important",
                                },
                                ".MuiFormHelperText-root": {
                                  color: "#798da3 !important",
                                },
                              },
                            ]}
                          />
                        </div>
                      </div>

                      {youtube.state && (
                        <div className="col-12 col-sm-6">
                          <div class="form-group">
                            <TextField
                              id="outlined-helperText"
                              label="Website Link"
                              className="w-100"
                              placeholder="https://bdx.app"
                              sx={[
                                { color: "white !important" },
                                { "*": { color: "white !important" } },
                                {
                                  input: {
                                    background: "transparent !important",
                                    padding: "3px 8px !important",
                                  },
                                  ".MuiFormHelperText-root": {
                                    color: "#798da3 !important",
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      )}

                      {github.state && (
                        <div className="col-12 col-sm-6 mt-2">
                          <div class="form-group">
                            <TextField
                              id="outlined-helperText"
                              label="Website Link"
                              className="w-100"
                              placeholder="https://bdx.app"
                              sx={[
                                { color: "white !important" },
                                { "*": { color: "white !important" } },
                                {
                                  input: {
                                    background: "transparent !important",
                                    padding: "3px 8px !important",
                                  },
                                  ".MuiFormHelperText-root": {
                                    color: "#798da3 !important",
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      )}

                      {twitter.state && (
                        <div className="col-12 col-sm-6 mt-2">
                          <div class="form-group">
                            <TextField
                              id="outlined-helperText"
                              label="Website Link"
                              className="w-100"
                              placeholder="https://bdx.app"
                              sx={[
                                { color: "white !important" },
                                { "*": { color: "white !important" } },
                                {
                                  input: {
                                    background: "transparent !important",
                                    padding: "3px 8px !important",
                                  },
                                  ".MuiFormHelperText-root": {
                                    color: "#798da3 !important",
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      )}

                      {reddit.state && (
                        <div className="col-12 col-sm-6 mt-2">
                          <div class="form-group">
                            <TextField
                              id="outlined-helperText"
                              label="Website Link"
                              className="w-100"
                              placeholder="https://bdx.app"
                              sx={[
                                { color: "white !important" },
                                { "*": { color: "white !important" } },
                                {
                                  input: {
                                    background: "transparent !important",
                                    padding: "3px 8px !important",
                                  },
                                  ".MuiFormHelperText-root": {
                                    color: "#798da3 !important",
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      )}

                      {telegram.state && (
                        <div className="col-12 col-sm-6 mt-2">
                          <div class="form-group">
                            <TextField
                              id="outlined-helperText"
                              label="Website Link"
                              className="w-100"
                              placeholder="https://bdx.app"
                              sx={[
                                { color: "white !important" },
                                { "*": { color: "white !important" } },
                                {
                                  input: {
                                    background: "transparent !important",
                                    padding: "3px 8px !important",
                                  },
                                  ".MuiFormHelperText-root": {
                                    color: "#798da3 !important",
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb21 mt-3">
                    <label className="fz16 mb8 text-white" htmlFor="file-4">
                      <AddBoxOutlinedIcon className="me-2" />
                      Add a description for your project
                    </label>
                    <div className="row mt-3">
                      <fieldset>
                        <textarea
                          placeholder="Tell us about the project you wan to launch"
                          rows="5"
                          tabIndex="4"
                          name="message"
                          className="message bg-transparent text-white c-textarea"
                          id="message"
                          required
                        />
                      </fieldset>
                    </div>
                    {/* <div
                      className="image_cta d-flex justify-content-center"
                      data-aos="fade-left"
                      data-aos-duration="1200"
                    >
                      <div className="project-box-style6 c-launchpreview mt-5">
                        <span className="boder"></span>
                        <div className="img-box">
                          <div className="image">
                            <img
                              src={require("../assets/images/common/project_1.png")}
                              className="w-100"
                              alt=""
                            />
                          </div>
                          <div className="content">
                            <div className="img-box">
                              <div className="image_inner">
                                <img
                                  className="mask"
                                  src={require("../assets/images/common/shape2.png")}
                                  alt=""
                                />
                                <div className="shape">
                                  <img
                                    src={require("../assets/images/common/shape2.png")}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="content-inner">
                                <h5 className="heading">
                                  <Link to="/project_details">
                                    SFSDFSDFSDFDS
                                  </Link>
                                </h5>
                                <p className="desc">xcvxcvxv</p>
                              </div>
                            </div>
                            <div className="content-bottom">
                              <div className="content-progress-box style2">
                                <div
                                  className="progress-bar"
                                  data-percentage="95%"
                                >
                                  <p className="progress-title-holder">
                                    <span className="progress-title">
                                      <span className="progress_number">
                                        <span className="white">
                                          Total Raised:{" "}
                                        </span>
                                        <span className="progress-number-mark">
                                          <span className="percent"></span>
                                        </span>
                                      </span>
                                      <span>$450,000 / $500,000</span>
                                    </span>
                                  </p>
                                  <div className="progress-content-outter">
                                    <div className="progress-content"></div>
                                  </div>
                                </div>
                              </div>
                              <ul className="sub-content">
                                <li>
                                  <p>Valuation</p>
                                  <p>278K</p>
                                </li>
                                <li>
                                  <p>Access</p>
                                  <p>Public Access</p>
                                </li>
                                <li>
                                  <p>Base allo</p>
                                  <p>$0</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* <div className="project-info-form style">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>7</h5>
                  </div>
                  <h5>
                    These features will be available after presale is created
                  </h5>
                </div>
                <div className="form-inner">
                  <div className="mb22">
                    <div className="col-12 img_1">
                      <div className="box c-launchtype-template">
                        <label>
                          <span className="title mb-0 mt-3 border-0 d-flex align-items-center">
                            <AutoAwesomeIcon />
                            <h5 className="mb-0 ms-2">Affiliate Earning</h5>
                          </span>
                          <span className="c-step7">
                            Enable affiliate earnings and when people refer
                            others to buy your presale, they will get a share of
                            the money from the people they bring in. This
                            encourages more people to join and helps you raise
                            more funds.
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 img_2">
                      <div className="box c-launchtype-template">
                        <label>
                          <span className="title mb-0 mt-3 border-0 d-flex align-items-center">
                            <EventAvailableIcon />
                            <h5 className="mb-0 ms-2">Vested Claim</h5>
                          </span>
                          <span className="c-step7">
                            With Vested Claim your users will be able to claim
                            their presale tokens periodically rather than at
                            once. Everytime a vesting period passes users will
                            be able to claim the vested tokens for that period.
                            The feature will be available after you create the
                            presale and must be enabled before your presale
                            start time!
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 img_3">
                      <div className="box c-launchtype-template">
                        <label>
                          <span className="title mb-0 mt-3 border-0 d-flex align-items-center">
                            <RecentActorsIcon />
                            <h5 className="mb-0 ms-2">Enable Whitelist</h5>
                          </span>
                          <span className="c-step7">
                            Create a whitelist and decide who gets to paticipate
                            in your presale. Whitelist can be enabled anytime
                            after you create a presale.
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="row w-100 d-flex justify-content-center">
                <TextField
                  id="outlined-helperText"
                  label="Referral code"
                  className="col-12 col-sm-10 col-md-7 col-lg-4 margin-auto"
                  helperText="Provide a correct referral code"
                  sx={[
                    { color: "white !important" },
                    { "*": { color: "white !important" } },
                    {
                      input: {
                        background: "transparent !important",
                        padding: "3px 8px !important",
                      },
                      ".MuiFormHelperText-root": {
                        color: "#798da3 !important",
                      },
                    },
                  ]}
                />
              </div>
              <div className="wrap-btn">
                <Button
                  title="Create Presale"
                  addclass="tf-button style1"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateSale;
