const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "Create",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "mint",
        links: "/create/bdx_mint",
      },
      {
        id: 2,
        sub: "Sales",
        links: "/create/bdx_sale",
      },
    ],
  },
  {
    id: 3,
    name: "Dashboard",
    links: "#",
    namesub: [
      {
        id: 4,
        sub: "Sales",
        links: "/bdx_sale",
      },
    ],
  },
];

export default menus;
