import img1 from "../images/common/team_1.png";
import img2 from "../images/common/team_2.png";
import img3 from "../images/common/team_3.png";
import img4 from "../images/common/team_4.png";
import img5 from "../images/common/team_5.png";
import img6 from "../images/common/team_6.png";
import img7 from "../images/common/team_7.png";
import img8 from "../images/common/team_8.png";

const dataTeam = [
  {
    id: 1,
    img: img1,
    name: "Darrell Steward",
    unit: "Senior Designer",
  },
  {
    id: 2,
    img: img2,
    name: "Kristin Watson",
    unit: "Senior Designer",
  },
  {
    id: 3,
    img: img3,
    name: "Jacob Jones",
    unit: "Senior Designer",
  },
  {
    id: 4,
    img: img4,
    name: "Jane Cooper",
    unit: "Verified Badge on ExplorersZero Hacks since 2020",
  },
  {
    id: 5,
    img: img5,
    name: "Jenny Wilson",
    unit: "Zero Hacks since 2020",
  },
  {
    id: 6,
    img: img6,
    name: "Dianne Russell",
    unit: "Senior Designer",
  },
  {
    id: 7,
    img: img7,
    name: "Devon Lane",
    unit: "Senior Designer",
  },
  {
    id: 8,
    img: img8,
    name: "Robert Fox",
    unit: "Senior Designer",
  },
];

export default dataTeam;
