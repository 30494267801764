import img1 from "../images/common/icon_1.png";
import img2 from "../images/common/icon_2.png";
import img3 from "../images/common/icon_3.png";
import img4 from "../images/common/icon_1.png";
import img5 from "../images/common/icon_4.png";
import img6 from "../images/common/icon_5.png";
import img7 from "../images/common/icon_6.png";
import img8 from "../images/common/icon_7.png";
import img9 from "../images/common/icon_8.png";

const dataFeatured = [
  {
    id: 1,
    img: img1,
    title: "BDX.Mint",
    text: "Crete a token, with an audited smart contract, securely.",
  },
  {
    id: 2,
    img: img2,
    title: "BDX.Launch",
    text: "Launch a sale to help devs raise funds for their subnets.",
  },
  {
    id: 3,
    img: img3,
    title: "Easy Withdrawal",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 4,
    img: img4,
    title: "Potential Project",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },

  {
    id: 5,
    img: img5,
    title: "A virtual world",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 6,
    img: img6,
    title: "Virtual reality",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 7,
    img: img7,
    title: "Other people",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 8,
    img: img8,
    title: "Persistence",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
  {
    id: 9,
    img: img9,
    title: "Connect with the world",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at nunc non ligula suscipit tincidunt at sit amet nunc.",
  },
];

export default dataFeatured;
