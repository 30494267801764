import React, { useState } from "react";

import PageTitle from "../components/pagetitle";
import CTA from "../components/cta/cta_v2";
import { Link } from "react-router-dom";
import LaunchTypeItem from "../components/launchtype";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ContactEmergencyOutlinedIcon from "@mui/icons-material/ContactEmergencyOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PercentIcon from "@mui/icons-material/Percent";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import GradingIcon from "@mui/icons-material/Grading";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GitHubIcon from "@mui/icons-material/GitHub";
import XIcon from "@mui/icons-material/X";
import RedditIcon from "@mui/icons-material/Reddit";
import TelegramIcon from "@mui/icons-material/Telegram";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CheckIcon from "@mui/icons-material/Check";

import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";

import Button from "../components/button";
import PreSale from "../components/presales";
function CreateMint(props) {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className="inner-page">
      {<PageTitle title="Submit Project" />}
      <PreSale
        title="Mint your own Token"
        items={[
          { title: "New Burn" },
          { title: "New Dividend" },
          { title: "New Standard" },
        ]}
      />
      <section className="tf-section project-info ">
        <div className="fluid px-5">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              {/* <div className="project-info-form style mw-100">
                <div className="title c-step d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="c-Number">
                      <h5>1</h5>
                    </div>
                    <h5>Choose a Network</h5>
                  </div>
                  <div>
                    <ul
                      className="panigation "
                      data-aos="fade-up"
                      data-aos-duration="800"
                    >
                      <li>
                        <Link to="#">
                          <svg
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.5 1L1.5 6L6.5 11"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="active">
                          1
                        </Link>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">3</Link>
                      </li>
                      <li>
                        <Link to="#">
                          <svg
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 1L6.5 6L1.5 11"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="choose-network flat-tabs overflow-hidden">
                  <ul className="menu-tab">
                    <li className="active justify-content-start p-0 ms-4">
                      <img
                        src={require("../assets/images/common/wallet_1.png")}
                        style={{ marginLeft: "-10px" }}
                        alt=""
                      />
                      <span className="ms-3">BSC</span>
                    </li>

                    <li className="active justify-content-start p-0 ms-4">
                      <img
                        src={require("../assets/images/common/wallet_2.png")}
                        style={{ marginLeft: "-10px" }}
                        alt=""
                      />
                      <span className="ms-3">ETH</span>
                    </li>

                    <li className="active justify-content-start p-0 ms-4">
                      <img
                        src={require("../assets/images/common/wallet_3.png")}
                        style={{ marginLeft: "-10px" }}
                        alt=""
                      />
                      <span className="ms-3">BTC</span>
                    </li>
                    <li className="active justify-content-start p-0 ms-4">
                      <img
                        src={require("../assets/images/common/wallet_4.png")}
                        style={{ marginLeft: "-10px" }}
                        alt=""
                      />
                      <span className="ms-3">XRP</span>
                    </li>

                    <li className="active justify-content-start p-0 ms-4">
                      <img
                        src={require("../assets/images/common/wallet_1.png")}
                        style={{ marginLeft: "-10px" }}
                        alt=""
                      />
                      <span className="ms-3">BSC</span>
                    </li>

                    <li className="active justify-content-start p-0 ms-4">
                      <img
                        src={require("../assets/images/common/wallet_2.png")}
                        style={{ marginLeft: "-10px" }}
                        alt=""
                      />
                      <span className="ms-3">ETH</span>
                    </li>

                    <li className="active justify-content-start p-0 ms-4">
                      <img
                        src={require("../assets/images/common/wallet_3.png")}
                        style={{ marginLeft: "-10px" }}
                        alt=""
                      />
                      <span className="ms-3">BTC</span>
                    </li>
                    <li className="active justify-content-start p-0 ms-4">
                      <img
                        src={require("../assets/images/common/wallet_4.png")}
                        style={{ marginLeft: "-10px" }}
                        alt=""
                      />
                      <span className="ms-3">XRP</span>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="project-info-form check-radio mw-100">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>1</h5>
                  </div>
                  <h5>Choose a Token Type</h5>
                </div>
                <div className="form-inner row">
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 col-12 wrapper-box mb-3">
                    <LaunchTypeItem
                      icon={<MonetizationOnIcon fontSize="large" />}
                      state="Success"
                      title="Standard Token"
                    />
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 col-12 wrapper-box mb-3">
                    <LaunchTypeItem
                      icon={<MonetizationOnIcon fontSize="large" />}
                      title="Burn Token"
                    />
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 col-12 wrapper-box mb-3">
                    <LaunchTypeItem
                      icon={<MonetizationOnIcon fontSize="large" />}
                      title="Fee Token"
                    />
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 col-12 wrapper-box mb-3">
                    <LaunchTypeItem
                      icon={<MonetizationOnIcon fontSize="large" />}
                      title="Dividend Token"
                    />
                  </div>
                </div>
                <div className="row mt-5 container-fluid mb-3">
                  <div className="title text-white col-12 border-0 mb-0 text-center">
                    Standard Token Features
                  </div>
                  <div className="col-md-4 col-lg-4 row">
                    <div className="col-1">
                      <CheckIcon fontSize="medium" style={{ color: "green" }} />
                    </div>
                    <div className="col-11">
                      Basic token with all standard features
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 row ">
                    <div className="col-1">
                      <CheckIcon fontSize="medium" style={{ color: "green" }} />
                    </div>
                    <div className="col-11">
                      Perfect for utility based projects such as DeFi apps
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 row">
                    <div className="col-1">
                      <CheckIcon fontSize="medium" style={{ color: "green" }} />
                    </div>
                    <div className="col-11">
                      Receive a DxSale Audit Certificate automatically upon
                      token Creation
                    </div>
                  </div>
                </div>
              </div>

              <div className="project-info-form style mw-100">
                <div className="title c-step">
                  <div className="c-Number">
                    <h5>2</h5>
                  </div>
                  <h5>Enter Token Information:</h5>
                </div>
                <div className="form-inner row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 mb-3">
                    <TextField
                      id="outlined-helperText"
                      label="Token Name*"
                      placeholder="Ex. 0x BX398B3A2B3B4B1455B123V1232154N"
                      className="w-100 c-launchtype-input"
                      sx={[
                        { color: "white !important", marginTop: "13px" },
                        { "*": { color: "white !important" } },
                        {
                          input: {
                            background: "transparent !important",
                            padding: "3px 8px !important",
                          },
                        },
                      ]}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 mb-3">
                    <TextField
                      id="outlined-helperText"
                      label="Token Symbol*"
                      placeholder="Ex. 0x BX398B3A2B3B4B1455B123V1232154N"
                      className="w-100 c-launchtype-input"
                      sx={[
                        { color: "white !important", marginTop: "13px" },
                        { "*": { color: "white !important" } },
                        {
                          input: {
                            background: "transparent !important",
                            padding: "3px 8px !important",
                          },
                        },
                      ]}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 mb-3">
                    <TextField
                      id="outlined-helperText"
                      label="Token Decimals*"
                      placeholder="Ex. 0x BX398B3A2B3B4B1455B123V1232154N"
                      className="w-100 c-launchtype-input"
                      sx={[
                        { color: "white !important", marginTop: "13px" },
                        { "*": { color: "white !important" } },
                        {
                          input: {
                            background: "transparent !important",
                            padding: "3px 8px !important",
                          },
                        },
                      ]}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 mb-3">
                    <TextField
                      id="outlined-helperText"
                      label="Token Total Supply*"
                      placeholder="Ex. 0x BX398B3A2B3B4B1455B123V1232154N"
                      className="w-100 c-launchtype-input"
                      sx={[
                        { color: "white !important", marginTop: "13px" },
                        { "*": { color: "white !important" } },
                        {
                          input: {
                            background: "transparent !important",
                            padding: "3px 8px !important",
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className="row w-100 d-flex justify-content-center">
                <TextField
                  id="outlined-helperText"
                  label="Referral code"
                  className="col-12 col-sm-10 col-md-7 col-lg-4 margin-auto"
                  helperText="Provide a correct referral code"
                  sx={[
                    { color: "white !important" },
                    { "*": { color: "white !important" } },
                    {
                      input: {
                        background: "transparent !important",
                        padding: "3px 8px !important",
                      },
                      ".MuiFormHelperText-root": {
                        color: "#798da3 !important",
                      },
                    },
                  ]}
                />
              </div>
              <div className="wrap-btn">
                <Button
                  title="Mint Standdard Token"
                  addclass="tf-button style1"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateMint;
