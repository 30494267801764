import React, { useState, useEffect } from "react";

export default function CustomSwitch(props) {
  const { value = false, label = "Here is the Label" } = props;

  const [isChecked, setIsChecked] = useState(value);

  const handleClick = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="d-flex align-items-center">
      <div
        className={`${isChecked ? "c-active c-switch-btn" : "c-switch-btn"}`}
        onClick={() => handleClick()}
      >
        <div className="c-sb-bg"></div>
        <div className="c-sb-cursor">
          <div></div>
        </div>
      </div>
      <div className="text-white ms-2">{label}</div>
    </div>
  );
}
