import React from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import "./styles.scss";

Ideas.propTypes = {
  data: PropTypes.array,
};

function Ideas(props) {
  const { data } = props;

  return (
    <section className="tf-section fueture ">
      <div className="fluid w_1320">
        <div className="row">
          <div className="col-md-12">
            <div className="fluid box">
              <div className="content mb-5 px-3 pb-5">
                <h2 className="title text-center">Build Your Ideas</h2>
              </div>
              <div className="row px-5 container">
                {data.slice(0, 2).map((item) => (
                  <div
                    className="pb-30 col-md-12 col-lg-6 col-sm-12 col-12 ideas"
                    key={item.id}
                  >
                    <div className="icon-box">
                      <div className="top">
                        <div className="icon">
                          <img src={item.img} alt="" className="ideas-icon" />
                        </div>
                        <div className="content mb-5">
                          <h5>
                            <Link to="#">{item.title}</Link>
                          </h5>
                          <p>{item.text}.</p>
                        </div>
                      </div>
                      <div className="bottom">
                        <img
                          src={require("../../assets/images/backgroup/bg_bt_box_1.png")}
                          alt=""
                        />
                      </div>
                      <div className="number text-black">{item.id}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ideas;
