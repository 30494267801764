import React from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

Partner3.propTypes = {
  data: PropTypes.array,
};

function Partner3(props) {
  const { data } = props;
  return (
    <section className="tf-section supported c-bdxsale">
      <div className="container w_1710 brand_wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="brand">
              <Swiper
                className="slider-12"
                spaceBetween={0}
                slidesPerView={10}
                autoplay
              >
                {data.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div to="/home_v3" className="image ">
                      <img src={item.img} alt="" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner3;
