import React from "react";
import "../styles.scss";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Button from "../../button";

Banner.propTypes = {
  data: PropTypes.array,
};

function Banner(props) {
  const { data } = props;
  React.useEffect(() => {
    console.log(data[0]);
  }, [data]);
  return (
    <div className="page-title">
      <div className="icon_bg">
        <img
          src={require("../../../assets/images/backgroup/bg_inner_slider.png")}
          alt="Risebot"
        />
      </div>
      <div className="slider-main">
        <div className="slider-st1">
          {/* <div className="overlay">
            <img
              src={require("../../../assets/images//backgroup/bg-slider.png")}
              alt="Risebot"
            />
          </div> */}
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <div className="box-slider px-5">
                  <div className="ms-3 col-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center">
                    <h1 className="title">{data[0].heading}</h1>
                    <p className="sub-title h4">{data[0].sub_heading}</p>
                    <div className="wrap-btn mt-5 row">
                      <Button
                        title="Checkout sale"
                        path="/"
                        addclass="style1 col-lg-3 col-md-5 col-sm-6 col-12"
                      />
                      <Button
                        title="Start Building"
                        path="/"
                        addclass="style2   col-lg-3  col-md-5 col-sm-6 col-12 offset-lg-1 offset-md-1"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div
                      className="tf-title"
                      data-aos="fade-right"
                      data-aos-duration="800"
                    >
                      <div className="img_technology">
                        <img
                          src={require("../../../assets/images/common/img_technology1.png")}
                          className="earth"
                          alt=""
                        />
                        <img
                          className="coin coin_1"
                          src={data[0].img1}
                          height={100}
                          width={100}
                          alt=""
                        />
                        <img
                          className="coin coin_2"
                          src={data[0].img2}
                          alt=""
                          height={250}
                          width={250}
                        />
                        <img
                          className="coin coin_3"
                          src={data[0].img3}
                          alt=""
                          height={200}
                          width={200}
                        />
                        <img
                          className="coin coin_4"
                          src={data[0].img4}
                          height={100}
                          width={100}
                          alt=""
                        />
                        <img
                          className="coin coin_5"
                          src={data[0].img5}
                          height={400}
                          width={400}
                          alt=""
                        />
                        <img
                          className="coin coin_6"
                          src={data[0].img6}
                          height={150}
                          width={150}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
